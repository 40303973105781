@import "~@/assets/scss/variables.scss";







































































































































































































































.module-design-basic-information .form-group.required label::after {
  content: '*';
  font-size: 0.85rem;
  margin-left: 0.25rem;
  color: $msi-orange;
}
