@import "~@/assets/scss/variables.scss";


























































































































































































































































































.sensor-config-container {
  height: calc(100vh - 105px)
}

.min-height-fit {
  min-height: fit-content;
}

.sensor-link-header {
  font-size: 1.8rem;
}

.view-datasheet {
  color: $msi-orange;
  cursor: pointer;
  font-weight: 500;
}

.view-datasheet:hover {
  color:$msi-orange-dark;
}
