@import "~@/assets/scss/variables.scss";




















































































































































































































































































.datasheet-title {
  font-size: 0.95rem;
  font-weight: 500;
}

.table-small-text {
  font-size: 0.8rem;
}

.datasheet-subtitle {
  font-weight: 500;
}

.datasheet-info {
  font-size: 0.8rem;
}
