@import "~@/assets/scss/variables.scss";




























































































































































































































































































































































































.button-icon {
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.button-icon:hover {
  transform: scale(1.05);
}

.button-icon:active {
  transform: scale(0.9);
}

.caret-right {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.caret-down {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

.coord-input::-webkit-outer-spin-button,
.coord-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.coord-input[type=number] {
  -moz-appearance: textfield;
}

.view-datasheet {
  color: $msi-orange;
  cursor: pointer;
  font-weight: 400;
}

.view-datasheet:hover {
  color: $msi-orange-dark;
}

.add-module-design {
  font-size: 0.85rem;
}
