@import "~@/assets/scss/variables.scss";































































































































.info-text {
  font-size: 0.8rem;
}
