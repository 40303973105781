@import "~@/assets/scss/variables.scss";































































































































































































































































































































































































.archived-button .btn.active {
  background-color: $msi-orange !important;
  color: white !important;
  border-color: $msi-orange !important;
}
