@import "~@/assets/scss/variables.scss";

















































































































































































































































































































































.module-design-title {
  font-weight: 500;
  font-size: 1.1rem;
}

.module-design-form {
  width: 100%;
}

.caret-right {
  transform: rotate(0deg);
  transition: transform 0.3s linear;
}

.caret-down {
  transform: rotate(90deg);
  transition: transform 0.3s linear;
}

.collapse-message {
  font-size: 0.75rem;
}

.form-toggler {
  cursor: pointer;
}

.view-datasheet {
  color: $msi-orange;
  cursor: pointer;
  font-weight: 400;
}

.view-datasheet:hover {
  color: $msi-orange-dark;
}
